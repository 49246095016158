import {
  Portfolio,
  Steps,
  Reviews,
  ContactUs,
  Tables,
  Discounts,
} from "./sections";
import { ToastContainer } from "react-toastify";
import Modal from "./components/Modal";
import BackToTopButton from "./components/BackToTopButton";

import "react-toastify/dist/ReactToastify.css";
import "./App.scss";

import logo from "./img/logo.svg";

import main_bg from "./img/main_bg.jpg";

import advantages from "./img/advantages/advantages.jpg";
import delivery from "./img/advantages/delivery.svg";
import waranty from "./img/advantages/waranty.svg";
import control from "./img/advantages/control.svg";
import quality from "./img/advantages/quality.svg";

import vk from "./img/social/VK.png";
import telegram from "./img/social/Telegram.png";
import watsup from "./img/social/Whatsapp.png";

function App() {
  return (
    <div className="App ">
      <header className="border-b lg:border-none">
        <div className="mx-auto max-w-7xl">
          <nav className="navbar navbar-expand-lg py-4 bg-white relative flex items-center w-full justify-between text-sm">
            <div className="px-4 w-full flex flex-wrap items-center justify-between">
              <a href="/">
                <img className="h-10 lg:h-14" src={logo} alt="Логотип" />
              </a>
              <div className="flex items-center">

                <a href="tel:+79264224774">
                  <svg
                    className="navbar__phone-icon lg:hidden mx-4"
                    fill="#000000"
                    height="32px"
                    width="32px"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 473.806 473.806"
                  >
                    <g>
                      <g>
                        <path
                          d="M374.456,293.506c-9.7-10.1-21.4-15.5-33.8-15.5c-12.3,0-24.1,5.3-34.2,15.4l-31.6,31.5c-2.6-1.4-5.2-2.7-7.7-4
                            c-3.6-1.8-7-3.5-9.9-5.3c-29.6-18.8-56.5-43.3-82.3-75c-12.5-15.8-20.9-29.1-27-42.6c8.2-7.5,15.8-15.3,23.2-22.8
                            c2.8-2.8,5.6-5.7,8.4-8.5c21-21,21-48.2,0-69.2l-27.3-27.3c-3.1-3.1-6.3-6.3-9.3-9.5c-6-6.2-12.3-12.6-18.8-18.6
                            c-9.7-9.6-21.3-14.7-33.5-14.7s-24,5.1-34,14.7c-0.1,0.1-0.1,0.1-0.2,0.2l-34,34.3c-12.8,12.8-20.1,28.4-21.7,46.5
                            c-2.4,29.2,6.2,56.4,12.8,74.2c16.2,43.7,40.4,84.2,76.5,127.6c43.8,52.3,96.5,93.6,156.7,122.7c23,10.9,53.7,23.8,88,26
                            c2.1,0.1,4.3,0.2,6.3,0.2c23.1,0,42.5-8.3,57.7-24.8c0.1-0.2,0.3-0.3,0.4-0.5c5.2-6.3,11.2-12,17.5-18.1c4.3-4.1,8.7-8.4,13-12.9
                            c9.9-10.3,15.1-22.3,15.1-34.6c0-12.4-5.3-24.3-15.4-34.3L374.456,293.506z M410.256,398.806
                            C410.156,398.806,410.156,398.906,410.256,398.806c-3.9,4.2-7.9,8-12.2,12.2c-6.5,6.2-13.1,12.7-19.3,20
                            c-10.1,10.8-22,15.9-37.6,15.9c-1.5,0-3.1,0-4.6-0.1c-29.7-1.9-57.3-13.5-78-23.4c-56.6-27.4-106.3-66.3-147.6-115.6
                            c-34.1-41.1-56.9-79.1-72-119.9c-9.3-24.9-12.7-44.3-11.2-62.6c1-11.7,5.5-21.4,13.8-29.7l34.1-34.1c4.9-4.6,10.1-7.1,15.2-7.1
                            c6.3,0,11.4,3.8,14.6,7c0.1,0.1,0.2,0.2,0.3,0.3c6.1,5.7,11.9,11.6,18,17.9c3.1,3.2,6.3,6.4,9.5,9.7l27.3,27.3
                            c10.6,10.6,10.6,20.4,0,31c-2.9,2.9-5.7,5.8-8.6,8.6c-8.4,8.6-16.4,16.6-25.1,24.4c-0.2,0.2-0.4,0.3-0.5,0.5
                            c-8.6,8.6-7,17-5.2,22.7c0.1,0.3,0.2,0.6,0.3,0.9c7.1,17.2,17.1,33.4,32.3,52.7l0.1,0.1c27.6,34,56.7,60.5,88.8,80.8
                            c4.1,2.6,8.3,4.7,12.3,6.7c3.6,1.8,7,3.5,9.9,5.3c0.4,0.2,0.8,0.5,1.2,0.7c3.4,1.7,6.6,2.5,9.9,2.5c8.3,0,13.5-5.2,15.2-6.9
                            l34.2-34.2c3.4-3.4,8.8-7.5,15.1-7.5c6.2,0,11.3,3.9,14.4,7.3c0.1,0.1,0.1,0.1,0.2,0.2l55.1,55.1
                            C420.456,377.706,420.456,388.206,410.256,398.806z"
                        />
                        <path
                          d="M256.056,112.706c26.2,4.4,50,16.8,69,35.8s31.3,42.8,35.8,69c1.1,6.6,6.8,11.2,13.3,11.2c0.8,0,1.5-0.1,2.3-0.2
                            c7.4-1.2,12.3-8.2,11.1-15.6c-5.4-31.7-20.4-60.6-43.3-83.5s-51.8-37.9-83.5-43.3c-7.4-1.2-14.3,3.7-15.6,11
                            S248.656,111.506,256.056,112.706z"
                        />
                        <path
                          d="M473.256,209.006c-8.9-52.2-33.5-99.7-71.3-137.5s-85.3-62.4-137.5-71.3c-7.3-1.3-14.2,3.7-15.5,11
                            c-1.2,7.4,3.7,14.3,11.1,15.6c46.6,7.9,89.1,30,122.9,63.7c33.8,33.8,55.8,76.3,63.7,122.9c1.1,6.6,6.8,11.2,13.3,11.2
                            c0.8,0,1.5-0.1,2.3-0.2C469.556,223.306,474.556,216.306,473.256,209.006z"
                        />
                      </g>
                    </g>
                  </svg>
                </a>
                <button
                  className="navbar-toggler border-0 py-3 lg:hidden leading-none text-xl bg-transparent text-black hover:text-gray-700 focus:text-gray-700 transition-shadow duration-150 ease-in-out mr-2"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContentY"
                  aria-controls="navbarSupportedContentY"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    className="w-5"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
                    ></path>
                  </svg>
                </button>
              </div>
              <div
                className="navbar-collapse collapse grow items-center"
                id="navbarSupportedContentY"
              >
                <div className="mx-auto max-w-7xl">
                  <ul className="navbar-nav mx-auto lg:flex lg:flex-row">
                    <li className="nav-item">
                      <a
                        className="nav-link block pr-2 lg:mx-6 lg:px-2 py-2 text-black hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out"
                        href="#advantages"
                        data-mdb-ripple="true"
                        data-mdb-ripple-color="light"
                      >
                        Преимущества
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link block pr-2 lg:mx-6 lg:px-2 py-2 text-black hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out"
                        href="#steps"
                        data-mdb-ripple="true"
                        data-mdb-ripple-color="light"
                      >
                        Этапы
                      </a>
                    </li>
                    <li className="nav-item mb-2 lg:mb-0">
                      <a
                        className="nav-link block pr-2 lg:mx-6 lg:px-2 py-2 text-black hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out"
                        href="#portfolio"
                        data-mdb-ripple="true"
                        data-mdb-ripple-color="light"
                      >
                        Примеры работ
                      </a>
                    </li>
                    <li className="nav-item mb-2 lg:mb-0">
                      <a
                        className="nav-link block pr-2 lg:mx-6 lg:px-2 py-2 text-black hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out"
                        href="#tables"
                        data-mdb-ripple="true"
                        data-mdb-ripple-color="light"
                      >
                        Столы
                      </a>
                    </li>
                    <li className="nav-item mb-2 lg:mb-0">
                      <a
                        className="nav-link block pr-2 lg:mx-6 lg:px-2 py-2 text-black hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out"
                        href="#reviews"
                        data-mdb-ripple="true"
                        data-mdb-ripple-color="light"
                      >
                        Отзывы
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="mr-10 flex flex-col font-bold">
                  <a href="tel:+79264224774">+7 926 422 4774</a>
                </div>
                <div className="flex my-5 lg:mt-0">
                  <a
                    className="px-2"
                    href="https://wa.me/79362224004"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img src={watsup} alt="watsup" />
                  </a>
                  <a
                    className="px-2"
                    href="https://vk.com/public218689546"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img src={vk} alt="vk" />
                  </a>
                  <a
                    className="px-2"
                    href="https://t.me/gosudarev_mebel"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img src={telegram} alt="telegram" />
                  </a>
                </div>
              </div>
              <div
                className="flex flex-row justify-start lg:justify-end w-full navbar-collapse collapse grow"
                id="navbarSupportedContentY"
              >
                <a class="mr-5" href="#discounts">
                  <button className="bg-black rounded-xl px-6 py-2 text-white text-opacity-80 hover:text-opacity-100 active:scale-95 transition-all">
                    Акции
                  </button>
                </a>
                <Modal />
              </div>
            </div>
          </nav>
        </div>
      </header>

      <section className="px-4 pb-10 lg:py-28">
        <main className="flex flex-wrap justify-center lg:justify-between mx-auto max-w-7xl">
          <div className="flex flex-col items-center lg:items-start justify-center max-w-[526px] py-4">
            <h1 className="text-2xl lg:text-6xl font-bold text-center lg:text-start">
              Проектирование и производство мебели
            </h1>
            <div className="max-w-[250px]">
              <p className="opacity-80 my-4 lg:my-12 text-center lg:text-start">
                Разрабатываем оригинальные и доступные решения для каждого
                клиента.
              </p>
            </div>
            <div className="flex justify-center space-x-4 mt-10">
              <a href="#contactus">
                <button className="bg-green-950 font-bold mb-5 lg:mb-0 px-4 py-2 lg:px-8 lg:py-4 rounded-xl hover:bg-black hover:text-white transition-all active:scale-95">
                  Оставить заявку
                </button>
              </a>
              <a href="tel:+79264224774">
                <button className="bg-green-950 font-bold mb-5 lg:mb-0 px-4 py-2 lg:px-8 lg:py-4 rounded-xl hover:bg-black hover:text-white transition-all active:scale-95">
                  Позвонить
                </button>
              </a>
            </div>
          </div>
          <div>
            <img
              width={636}
              src={main_bg}
              alt="Изображение мебели и интерьера"
              loading="lazy"
            />
          </div>
        </main>
      </section>
      <section
        id="advantages"
        className="px-4 pb-10 lg:py-28 bg-gray-950 py-28"
      >
        <div className="mx-auto max-w-7xl flex  justify-between">
          <div className="hidden lg:block">
            <img width={526} src={advantages} alt="Преимущества" />
          </div>
          <div className="flex flex-col items-center justify-center">
            <div className="flex flex-col items-center">
              <h3 className="text-2xl lg:text-4xl text-white font-bold mb-10 lg:mb-20">
                Почему именно мы?
              </h3>
              <div className="flex justify-center">
                <ul>
                  <li className="flex mb-8 items-center text-xl lg:text-2xl text-white">
                    <img
                      className="mr-6"
                      src={delivery}
                      alt="Бесплатная доставка"
                    />
                    <p>Бесплатная доставка</p>
                  </li>
                  <li className="flex mb-8 items-center text-xl lg:text-2xl text-white">
                    <img
                      className="mr-6"
                      src={waranty}
                      alt="Бесплатная доставка"
                    />
                    <p>Гарантия 36 месяцев</p>
                  </li>
                  <li className="flex mb-9 items-center text-xl lg:text-2xl text-white">
                    <img
                      className="mr-6"
                      src={control}
                      alt="Бесплатная доставка"
                    />
                    <p>Контроль каждого этапа производства</p>
                  </li>
                  <li className="flex mb-8 items-center text-xl lg:text-2xl text-white">
                    <img
                      className="mr-6"
                      src={quality}
                      alt="Бесплатная доставка"
                    />
                    <p>100% качество материалов</p>
                  </li>
                  <li className="flex mb-8 items-center text-xl lg:text-2xl text-white">
                    <img className="mr-6" src={quality} alt="<MGS" />
                    <p>
                      Изделия от ведущего производителя мебели - <br /> фабрики
                      MGS (г. Пенза)
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center space-x-4 mt-10">
          <a href="#contactus">
            <button className="bg-green-950 font-bold mb-5 lg:mb-0 px-4 py-2 lg:px-8 lg:py-4 rounded-xl hover:bg-black hover:text-white transition-all active:scale-95">
              Оставить заявку
            </button>
          </a>
          <a href="tel:+79264224774">
            <button className="bg-green-950 font-bold mb-5 lg:mb-0 px-4 py-2 lg:px-8 lg:py-4 rounded-xl hover:bg-black hover:text-white transition-all active:scale-95">
              Позвонить
            </button>
          </a>
        </div>
      </section>
      <section id="discounts" className="px-4 py-10 lg:py-28">
        <Discounts />
      </section>
      <section id="steps" className="px-4 py-10 lg:py-28 bg-gray-950">
        <h3 className="text-xl lg:text-4xl text-white font-bold mb-20 text-center">
          Порядок выполнения работ
        </h3>
        <div className="mx-auto max-w-7xl flex">
          <Steps />
        </div>
        <div className="flex justify-center space-x-4 mt-10">
          <a href="#contactus">
            <button className="bg-green-950 font-bold mb-5 lg:mb-0 px-4 py-2 lg:px-8 lg:py-4 rounded-xl hover:bg-black hover:text-white transition-all active:scale-95">
              Оставить заявку
            </button>
          </a>
          <a href="tel:+79264224774">
            <button className="bg-green-950 font-bold mb-5 lg:mb-0 px-4 py-2 lg:px-8 lg:py-4 rounded-xl hover:bg-black hover:text-white transition-all active:scale-95">
              Позвонить
            </button>
          </a>
        </div>
      </section>
      <section id="portfolio" className="px-4 pt-10 lg:py-28">
        <div className="mx-auto max-w-7xl">
          <Portfolio />
          <div className="flex justify-center mt-10 space-x-4">
            <a
              target={"_blank"}
              rel="noreferrer"
              href="https://vk.com/albums-218689546"
            >
              <button className="bg-green-950 font-bold mb-5 lg:mb-0 px-4 py-2 lg:px-8 lg:py-4 rounded-xl hover:bg-black hover:text-white transition-all active:scale-95">
                Смотреть еще
              </button>
            </a>
            <a href="#contactus">
              <button className="bg-green-950 font-bold mb-5 lg:mb-0 px-4 py-2 lg:px-8 lg:py-4 rounded-xl hover:bg-black hover:text-white transition-all active:scale-95">
                Оставить заявку
              </button>
            </a>
            <a href="tel:+79264224774">
              <button className="bg-green-950 font-bold mb-5 lg:mb-0 px-4 py-2 lg:px-8 lg:py-4 rounded-xl hover:bg-black hover:text-white transition-all active:scale-95 portfolio__call-button">
                Позвонить
              </button>
            </a>
          </div>
        </div>
      </section>
      <section id="tables" className="px-4 py-10 lg:py-28 bg-gray-950">
        <Tables />
        <div className="flex justify-center space-x-4 mt-10">
          <a href="#contactus">
            <button className="bg-green-950 font-bold mb-5 lg:mb-0 px-4 py-2 lg:px-8 lg:py-4 rounded-xl hover:bg-black hover:text-white transition-all active:scale-95">
              Оставить заявку
            </button>
          </a>
          <a href="tel:+79264224774">
            <button className="bg-green-950 font-bold mb-5 lg:mb-0 px-4 py-2 lg:px-8 lg:py-4 rounded-xl hover:bg-black hover:text-white transition-all active:scale-95">
              Позвонить
            </button>
          </a>
        </div>
      </section>
      <section id="reviews" className="px-4 py-10 lg:py-28">
        <div className="flex flex-wrap justify-center lg:justify-between mx-auto max-w-7xl">
          <div className="flex flex-col items-center lg:items-start justify-center max-w-md py-5">
            <h3 className="text-4xl font-bold text-center">Отзывы</h3>
            <p className="py-6 text-center lg:text-start lg:py-14">
              Мы ценим наших клиентов, нам важно мнение каждого покупателя. Мы
              уверены в своей работе и не боимся показывать реальные отзывы
              заказчиков.
            </p>
            <div className="flex justify-center space-x-4 mt-10">
              <a href="#contactus">
                <button className="bg-green-950 font-bold mb-5 lg:mb-0 px-4 py-2 lg:px-8 lg:py-4 rounded-xl hover:bg-black hover:text-white transition-all active:scale-95">
                  Оставить заявку
                </button>
              </a>
              <a href="tel:+79264224774">
                <button className="bg-green-950 font-bold mb-5 lg:mb-0 px-4 py-2 lg:px-8 lg:py-4 rounded-xl hover:bg-black hover:text-white transition-all active:scale-95 reviews__call-button">
                  Позвонить
                </button>
              </a>
            </div>
          </div>
          <div className="flex max-w-xl">
            <Reviews />
          </div>
        </div>
      </section>
      <section id="contactus" className=" bg-gray-950 px-4 py-10 lg:py-28">
        <h3 className="text-xl lg:text-4xl text-white font-bold mb-5 lg:mb-10 text-center">
          Свяжитесь с нами
        </h3>
        <div className="mx-auto max-w-md">
          <ContactUs />
        </div>
        <div className="flex justify-end max-w-7xl mx-auto mt-4">
          <a
            className="px-2"
            href="https://wa.me/79362224004"
            target={"_blank"}
            rel="noreferrer"
          >
            <img src={watsup} alt="watsup" />
          </a>
          <a
            className="px-2"
            href="https://vk.com/public218689546"
            target={"_blank"}
            rel="noreferrer"
          >
            <img src={vk} alt="vk" />
          </a>
          <a
            className="px-2"
            href="https://t.me/gosudarev_mebel"
            target={"_blank"}
            rel="noreferrer"
          >
            <img src={telegram} alt="telegram" />
          </a>
        </div>
      </section>
      <footer>
        <div className="mx-auto max-w-7xl">
          <div className="py-14 flex flex-wrap flex-col lg:flex-row items-center">
            <div>
              <a href="/">
                {" "}
                <img src={logo} alt="Logo" />
              </a>
            </div>
            <ul className="navbar-nav mx-auto lg:flex lg:flex-row text-center lg:text-start my-4">
              <li className="nav-item">
                <a
                  className="nav-link block pr-2 lg:mx-6 lg:px-2 py-2 text-black hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out"
                  href="#advantages"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                >
                  Преимущества
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link block pr-2 lg:mx-6 lg:px-2 py-2 text-black hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out"
                  href="#steps"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                >
                  Этапы
                </a>
              </li>
              <li className="nav-item mb-2 lg:mb-0">
                <a
                  className="nav-link block pr-2 lg:mx-6 lg:px-2 py-2 text-black hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out"
                  href="#portfolio"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                >
                  Примеры работ
                </a>
              </li>
              <li className="nav-item mb-2 lg:mb-0">
                <a
                  className="nav-link block pr-2 lg:mx-6 lg:px-2 py-2 text-black hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out"
                  href="#tables"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                >
                  Столы
                </a>
              </li>
              <li className="nav-item mb-2 lg:mb-0">
                <a
                  className="nav-link block pr-2 lg:mx-6 lg:px-2 py-2 text-black hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out"
                  href="#reviews"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                >
                  Отзывы
                </a>
              </li>
            </ul>
            <div className="flex flex-col mr-4">
              <a href="tel:+79264224774">+7 926 422 4774</a>
            </div>
            <div className="flex mt-5 lg:mt-0">
              <a
                className="px-2"
                href="https://wa.me/79362224004"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={watsup} alt="watsup" />
              </a>
              <a
                className="px-2"
                href="https://vk.com/public218689546"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={vk} alt="vk" />
              </a>
              <a
                className="px-2"
                href="https://t.me/gosudarev_mebel"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={telegram} alt="telegram" />
              </a>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row justify-between mb-6 text-center lg:text-start">
            <span>ОГРН: 322774600188529</span>
            <span className="my-5 lg:my-0">БИК 044525974</span>
            <span>
              Юридический адрес: 108801, Россия, г. Москва, Сосенеское П., П.
              Коммунарка, ул. Липовый парк, д. 6, кв. 95
            </span>
          </div>
        </div>
        <BackToTopButton />
      </footer>
      <ToastContainer />
    </div>
  );
}

export default App;
