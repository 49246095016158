import "swiper/css";
import "swiper/css/navigation";

import present from "../../img/discounts/present.png";
import kuppers from "../../img/discounts/kuppers.png";
import novoseli from "../../img/discounts/novoseli.webp";
import pens from "../../img/discounts/pens.png";
import discount__table from "../../img/discounts/discount__table.jpg";
import project from "../../img/discounts/project.jpg";
import svo from "../../img/discounts/svo.jpg";

const Discounts = () => {
  return (
    <>
      <h3 className="text-3xl font-bold mb-10 lg:mb-20 text-center">
        Акции и подарки<sup>*</sup>
      </h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4">
        <div className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
          <img
            src={present}
            className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-s-lg"
            alt="present"
          />

          <div
            className="flex flex-col justify-between p-4 leading-normal"
            style={{ position: "relative", paddingBottom: "4rem" }}
          >
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Один из двух подарков на выбор
            </h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 text-xs">
              Каждый заказчик может выбрать один из двух подарков: мойка из
              искусственного камня или встраиваемая вытяжка. Просто сделайте
              заказ, и получите подарок!
            </p>
            <a
              href="#contactus"
              className="bg-green-950 font-bold hover:bg-black hover:text-white transition-all active:scale-95 inline-flex items-center px-3 py-2 text-sm font-medium text-center rounded-lg"
              style={{ position: "absolute", bottom: "1rem" }}
            >
              Получить подарок
            </a>
          </div>
        </div>

        <div className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
          <img
            src={kuppers}
            className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-s-lg"
            alt="kuppers-img"
          />
          <div
            className="flex flex-col justify-between p-4 leading-normal"
            style={{ position: "relative", paddingBottom: "4rem" }}
          >
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Скидка до 20% на бытовую технику Kuppersberg{" "}
            </h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 text-xs">
              При покупке кухонного гарнитура скидка на всю бытовую технику
              марки Kuppersberg до 20%. Пройдите на{" "}
              <a
                href="https://kuppersberg.ru/"
                class="font-medium font-semibold dark:text-blue-500 hover:underline"
                target="_blank" rel="noreferrer"
              >
                оф. сайт Kuppersberg
              </a>
              , выберите технику, и получите вашу скидку у нас.
            </p>
            <a
              href="#contactus"
              className="bg-green-950 font-bold hover:bg-black hover:text-white transition-all active:scale-95 inline-flex items-center px-3 py-2 text-sm font-medium text-center rounded-lg"
              style={{ position: "absolute", bottom: "1rem" }}
            >
              Получить скидку
            </a>
          </div>
        </div>

        <div className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
          <img
            src={novoseli}
            className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-s-lg"
            alt="novoseli"
          />

          <div
            className="flex flex-col justify-between p-4 leading-normal"
            style={{ position: "relative", paddingBottom: "4rem" }}
          >
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Скидка 10% для новосёлов
            </h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 text-xs">
              Купили квартиру? Разменялись? Переехали? Для вас скидка 10%! Мы
              понимаем, что переезд в новое жилье - это важное и радостное
              событие, но в то же время оно может быть связано с большими
              расходами.
            </p>
            <a
              href="#contactus"
              className="bg-green-950 font-bold hover:bg-black hover:text-white transition-all active:scale-95 inline-flex items-center px-3 py-2 text-sm font-medium text-center rounded-lg"
              style={{ position: "absolute", bottom: "1rem" }}
            >
              Получить скидку
            </a>
          </div>
        </div>

        <div className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
          <img
            src={pens}
            className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-s-lg"
            alt="pens-img"
          />

          <div
            className="flex flex-col justify-between p-4 leading-normal"
            style={{ position: "relative", paddingBottom: "4rem" }}
          >
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Скидка 10% для пенсионеров
            </h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 text-xs">
              Просто покажите любой документ, подтверждающий статус пенсионера и
              получите скидку 10%. Для этого достаточно предъявить пенсионное
              удостоверение или справку из Пенсионного фонда.
            </p>
            <a
              href="#contactus"
              className="bg-green-950 font-bold hover:bg-black hover:text-white transition-all active:scale-95 inline-flex items-center px-3 py-2 text-sm font-medium text-center rounded-lg"
              style={{ position: "absolute", bottom: "1rem" }}
            >
              Получить скидку
            </a>
          </div>
        </div>

        <div className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
          <img
            src={discount__table}
            className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-s-lg"
            alt="discount__table-img"
          />

          <div
            className="flex flex-col justify-between p-4 leading-normal"
            style={{ position: "relative", paddingBottom: "4rem" }}
          >
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Скидка 20% на обеденные столы при покупке кухни
            </h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 text-xs">
              Столы из керамогранита. Премиум качество по средней цене.
            </p>
            <a
              href="#contactus"
              className="bg-green-950 font-bold hover:bg-black hover:text-white transition-all active:scale-95 inline-flex items-center px-3 py-2 text-sm font-medium text-center rounded-lg"
              style={{ position: "absolute", bottom: "1rem" }}
            >
              Получить скидку
            </a>
          </div>
        </div>

        <div className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
          <img
            src={project}
            className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-s-lg"
            alt="project"
          />

          <div
            className="flex flex-col justify-between p-4 leading-normal"
            style={{ position: "relative", paddingBottom: "4rem" }}
          >
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Бесплатный замер, проект и доставка
            </h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 text-xs">
              При заключении договора на кухню разработка дизайн-проекта, выезд
              замерщика, и доставка в пределах московской области - бесплатно.
            </p>
            <a
              href="#contactus"
              className="bg-green-950 font-bold hover:bg-black hover:text-white transition-all active:scale-95 inline-flex items-center px-3 py-2 text-sm font-medium text-center rounded-lg"
              style={{ position: "absolute", bottom: "1rem" }}
            >
              Участвовать в акции
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 my-4">
  <img
    src={svo}
    className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-1/2 md:rounded-none md:rounded-l-lg"
    alt="svo-img"
  />

  <div
    className="flex flex-col justify-between p-4 leading-normal"
    style={{ position: "relative", paddingBottom: "4rem" }}
  >
    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
      Скидка 20% ветеранам и участникам СВО 
    </h5>
    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 text-xs">
    Ветеранам и участникам СВО предоставляем скидку 20% на всю продукцию нашей компании. <br></br> 
    Для этого достаточно предъявить удостоверение подтверждающее участие в СВО. 
    </p>
    <p>Мы вседа и везде за наших!</p>
    <a
      href="#contactus"
      className="bg-green-950 font-bold hover:bg-black hover:text-white transition-all active:scale-95 inline-flex items-center px-3 py-2 text-sm font-medium text-center rounded-lg"
      style={{ position: "absolute", bottom: "1rem" }}
    >
      Участвовать в акции
    </a>
    
  </div>
</div>
      <div className="mx-auto w-4/5 text-center">
        <p class="mb-3 text-gray-500 dark:text-gray-400 font-semibold mt-5 text-gray-700 dark:text-gray-400">
          <sup>*</sup>по вопросам связанным с акциями и выяснения, какие акции
          могут суммироваться, обратитесь к менеджеру.
        </p>
      </div>
      <div className="flex justify-center space-x-4 mt-10">
        <a href="#contactus">
          <button className="bg-green-950 font-bold mb-5 lg:mb-0 px-4 py-2 lg:px-8 lg:py-4 rounded-xl hover:bg-black hover:text-white transition-all active:scale-95">
            Оставить заявку
          </button>
        </a>
        <a href="tel:+79264224774">
          <button className="bg-green-950 font-bold mb-5 lg:mb-0 px-4 py-2 lg:px-8 lg:py-4 rounded-xl hover:bg-black hover:text-white transition-all active:scale-95">
            Позвонить
          </button>
        </a>
      </div>
    </>
  );
};

export default Discounts;
